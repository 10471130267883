import React from 'react'
import { env } from '../config';
import { SmallLinesinFeatureMainTitle } from './SVGicons'

function FeaturePageFeatureCards(props: any) {
  const { FeaturePageSingleFeatureContent } = props;

  return (
    <div className='flex flex-col bg-[#FFF7F7]'>

      {
        FeaturePageSingleFeatureContent?.map((node: any, i: number) => (
          <div key={node.id} className='px-2 md:px-8 xl:px-32 py-3 md:py-12 my-2'>
            <div className={`mx-auto flex ${i % 2 === 0 ? "flex-col-reverse" : "flex-col"} lg:flex-row  justify-between `}>
              {
                i % 2 !== 0 ?
                  <div className='lg:w-1/2 flex justify-center items-center '
                  // data-aos="fade-right"
                  >
                    <img className='scale-[60%] lg:scale-[100%] ' src={`${env}${node?.FeaturePageSingleFeatureSVGImage?.data?.attributes?.url}`} alt={"single feature title svg"} />
                  </div> : null
              }
              <div className=' flex flex-col lg:grid grid-cols-2 gap-2 w-full lg:w-fit  mx-auto '
              // data-aos={i%2===0?"fade-right":"fade-left"}
              >
                <div className=' lg:rounded-tl-[50px] text-[#038577]  lg:bg-[#038577] m-1 lg:w-[270px] lg:h-[270px] lg:shadow-lg text-[24px] font-bold lg:text-white px-6 lg:py-12 flex flex-col' data-aos="zoom-in">
                  <div className='mx-auto  md:text-left text-center'>{node.FeatureMainHeading}</div>
                  <div className='lg:flex hidden'>
                    <SmallLinesinFeatureMainTitle />
                  </div>
                </div>
                <div className=' lg:rounded-tr-[50px] lg:bg-white m-1 lg:w-[270px] lg:h-[270px] lg:shadow-lg p-2 flex flex-col justify-center text-center text-[#1F3934] ' data-aos="zoom-in">
                  <div className='flex justify-center'>
                    <div className=' '>
                      <img src={`${env}${node?.SingleSubFeatureContent[0]?.SubFeatureSVGImage?.data?.attributes?.url}`} alt={"single subfeature title svg"} />
                    </div>
                  </div>
                  <div className='font-bold text-[16px] my-3'>{node.SingleSubFeatureContent[0].SubFeatureTitle}</div>
                  <div className='text-[16px]'>{node.SingleSubFeatureContent[0].SubFeatureDescription}</div>
                </div>
                <div className='lg:rounded-bl-[50px]  lg:bg-white m-1 lg:w-[270px] lg:h-[270px] lg:shadow-lg p-2 flex flex-col justify-center text-center ' data-aos="zoom-in">
                  <div className='flex justify-center'>
                    <div className=''>
                      <img src={`${env}${node?.SingleSubFeatureContent[1]?.SubFeatureSVGImage?.data?.attributes?.url}`} alt={"single subfeature title svg"} />
                    </div>
                  </div>
                  <div className='font-bold text-[16px] my-3'>{node.SingleSubFeatureContent[1].SubFeatureTitle}</div>
                  <div className='text-[16px]'>{node.SingleSubFeatureContent[1].SubFeatureDescription}</div>
                </div>
                <div className='lg:rounded-br-[50px]  m-1 lg:w-[270px] lg:h-[270px] lg:shadow-lg p-2 flex flex-col justify-center text-center ' data-aos="zoom-in">
                  <div className='flex justify-center'>
                    <div className=''>
                      <img src={`${env}${node?.SingleSubFeatureContent[2]?.SubFeatureSVGImage?.data?.attributes?.url}`} alt={"single subfeature title svg"} />
                    </div>
                  </div>
                  <div className='font-bold text-[16px] my-3'>{node.SingleSubFeatureContent[2].SubFeatureTitle}</div>
                  <div className='text-[16px]'>{node.SingleSubFeatureContent[2].SubFeatureDescription}</div>
                </div>
              </div>
              {
                i % 2 === 0 ?
                  <div className='lg:w-1/2 flex justify-center items-center '>
                    <img className='scale-[60%] lg:scale-[100%]' src={`${env}${node?.FeaturePageSingleFeatureSVGImage?.data?.attributes?.url}`} alt={"single feature title svg"} />
                  </div> : null
              }
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default FeaturePageFeatureCards