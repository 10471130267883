import React from 'react'
import { LOGOSVG } from './SVGicons';
import { Link } from 'react-router-dom';


function LandingPageHeader(props: any) {
    const { background, ButtonText } = props;
    return (
        <div className={`flex flex-row  items-center px-5 lg:px-[8%] justify-between w-full h-12 md:h-16 sticky top-0 bg-${background}  z-10 select-none`}>
            <Link to={"/"} className="scale-[60%] md:scale-[80%] lg:scale-[90%] xl:scale-[100%] sticky">
                <LOGOSVG />
            </Link>
            <Link to='https://hrmnest.com/app/' target="_blank">
                <button className='hover:bg-[#03776B] active:bg-[#024F46] bg-[#038577] px-3 py-2 rounded-md text-white sm:w-max '>
                    {ButtonText}
                </button>
            </Link>
        </div>
    )
}

export default LandingPageHeader