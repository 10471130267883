

//local
// export const env="http://localhost:1337";


// testing
// export const env="https://64.227.158.67:1337";
// export const env="https://test.hrmnest.com/api"



// production
export const env="https://us4-staging.hrmnest.com/api";

