import React from 'react'
import { AssetManagement, AttendanceManagement, LeaveBuilder, LeaveManagement, LeavePolicy, Onboarding, OrganisationManagement, WorkFlowManagement } from './SVGicons'
import { Link } from 'react-router-dom';
import { env } from '../config';

const LandinPageBubbleSection = (props: any) => {
    const { BubbleSection } = props;
    return (
        <div className='lg:mx-[5%]  md:flex flex-col lg:flex-row justify-evenly  px-5 lg:px-0 '>
            <div className='lg:w-[50%] xl:w-[40%] my-auto'>
                <div data-aos="fade-up" className='text-[36px] md:text-[48px] mx-auto font-extrabold text-[#1F3934]'>
                    {BubbleSection?.Title}
                </div>
                <div data-aos="fade-up" className='text-[16px] md:text-[20px] my-6'>
                    {BubbleSection?.Description}
                </div>
                <div className='block md:hidden'>


                    {BubbleSection?.Card?.map((node: any, index: number) => {
                        return (
                            <Link to={`/modules/${node?.Title === "Leave Builder" || node?.Title === "Leave Policy" || node?.Title === "Leave Management System" ? "leave-builder" : node?.Title === "Onboarding" || node?.Title === "Attendance Management" ? "people-management" : node?.Title === "Asset Management System" || node?.Title === "Organization Management" || node?.Title === "Workflow Management" ? "organization-operation" : null}`}>
                                <div data-aos="flip-down" data-aos-delay={`${index === 0 ? 0 : index * 200}`} className=" mx-auto  flex  my-2  flex-row  px-4 py-3 rounded-xl border-[#00000025] border items-center text-[16px] font-semibold w-full md:max-w-[100%]" >
                                    <div className='min-w-[35px]' >
                                        <img src={`${env}${node?.image?.data?.attributes?.url}`} alt={node?.Title} />
                                    </div>
                                    <div className="ml-4 text-[16px] lg:text-[20px]">{node?.Title}</div>
                                </div>
                            </Link>

                        )
                    })}
                </div>


                <Link to='https://hrmnest.com/app/' target="_blank">

                    <button data-aos="fade-up" className='hover:bg-[#03776B] my-5 active:bg-[#024F46] bg-[#038577] px-5 py-3 rounded-md text-white mr-4 text-[14px] md:text-[16px]'>{BubbleSection?.ButtonText}</button>
                </Link>
            </div>



            <div data-aos="zoom-in" className='md:w-[550px] md:h-[550px] md:block hidden  relative mx-auto lg:mx-0'>
                <WorkFlowManagement position="absolute  scale-[125%] top-[15%] left-[45%]" />
                <AssetManagement position="absolute top  scale-[125%] top-[53%] left-[75%]" />
                <OrganisationManagement position="absolute scale-[125%] top-[25%] left-[70%] scale-[125%]" />
                <LeaveBuilder position="absolute scale-[125%] top-[15%] left-[20%]" />
                <Onboarding position="absolute scale-[125%] left-[20%] top-[48%]  -translate-x-[50%] -translate-y-[50%]" />
                <AttendanceManagement position="absolute scale-[125%] top-[60%] left-[17%]" />
                <LeaveManagement position="absolute scale-[125%] top-[64%] left-[47%]" />
                <LeavePolicy position="absolute scale-[125%] top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]" />
            </div>

        </div>
    )
}

export default LandinPageBubbleSection


