import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// import { ENV } from "../redux/actions";
import FooterLinkSection from "./FooterLinkSection";
import { LOGOSVG, UpWave } from "./SVGicons";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
} from "./SVGicons";

function Footer(props: any) {
  const { wave, background } = props;

  const FooterData: any = useSelector(
    (state: any) => state?.DataReducer?.FooterContent
  );



  // console.log("FOOTER", FooterData);

  return (
    <>
      {wave ?
        <UpWave background={background} />
        : null

      }
      <div className={` flex flex-col  justify-center pb-3 md:pt-20 text-[#1F3934]`}
        style={{ backgroundColor: `${background}` }}
      >
        <div className="flex-col flex w-full xl:w-[85%] md:flex md:flex-row md:p-3 xl:justify-between md:border-b-0.5 xl:h-[220px] md:border-gray-300 xl:mx-auto  ">
          <div className=" p-1 " data-aos="fade-up">
            <div >
              <Link to="/" className="flex mx-auto md:flex  justify-center my-2 md:my-0 md:justify-start  scale-[70%] md:scale-[80%] lg:scale-[90%] xl:scale-[100%]">
                <LOGOSVG />
              </Link>
              <div className="mt-4  font-body w-80  text-1  md:flex hidden">
                {FooterData?.FooterDescriptionText}
              </div>
              {/* <div className=" footericons px-6 md:px-1  md:w-[200px] w-4/5 hidden sm:flex flex-row p-1  mt-5 justify-between  ">
              <a
                href={FooterData?.FacebookURL}
                target={"_blank"}
              >
                <FacebookIcon />
              </a>
              <a href={FooterData?.InstagramURL}>
                <InstagramIcon />
              </a>
              <a href={FooterData?.TwitterURL} target={"_blank"}>
                <TwitterIcon />
              </a>
              <a href={FooterData?.LinkedInURL}>
                <LinkedinIcon />
              </a>
            </div> */}
            </div>
          </div>
          <div className=" px-2 sm:p-2 py-3 sm:py-0 flex flex-col sm:flex-row w-auto justify-evenly flex-1 " data-aos="fade-up">


            <div className=" w-auto px-2 sm:p-2 py-1 ">
              <FooterLinkSection
                title={"HRMNest"}
                data={[
                  { text: "Features", path: "/features", type: "inside-link" },
                  { text: "Pricing", path: "/offerings", type: "inside-link" },
                  { text: "Contact", path: "/contact-us", type: "inside-link" },
                  // { text: "Privacy Policy", path: "/privacy-policy" },
                ]}
              />
            </div>



            {/* <div className=" w-auto px-2 sm:p-2  py-1  ">
            <FooterLinkSection
              title={"Developers"}
              data={[
                { text: "Hoolva SDK", path: "/hoolva-sdk",type:"inside-link" },
                { text: "Documentation", path: "https://docs.hoolva.com",type:"outside-link" },
                // { text: "Mobile SDK", path: "/mobile-sdk" },
                // { text: "API Reference", path: "/api-reference" },
                // { text: "Help Center", path: "/help-center" },
              ]}
            />
          </div> */}



            <div className=" w-auto px-2 sm:p-2 py-1  ">
              <FooterLinkSection
                title={"Modules"}
                data={[
                  {
                    text: "People Management",
                    path: "/modules/people-management",
                    type: "inside-link"
                  },
                  {
                    text: "Leave Builder",
                    path: "/modules/leave-builder",
                    type: "inside-link"
                  },
                  {
                    text: "Organization Operations",
                    path: "/modules/organization-operation",
                    type: "inside-link"
                  },

                  // {
                  //   text: "Hiring Management",
                  //   path: "/modules/hiring-management",
                  //   type: "inside-link"
                  // },


                  // { text: "Terms and Conditions", path: "/terms-and-conditions" },
                ]}
              />
            </div>



            <div className=" w-auto px-2 sm:p-2  py-1">
              <FooterLinkSection
                title={"Resources"}
                data={[
                  // { text: "Case Studies", path: "/case-studies" },
                  // { text: "Use Cases", path: "/use-cases" },
                  // { text: "Developer Connect", path: "/offerings", type: "inside-link" },
                  { text: "Privacy Policy", path: "/privacypolicy", type: "outside-link" },
                  { text: "Documentation", path: "https://docs.hrmnest.com/", type: "outside-link" },
                ]}
              />
            </div>
            {/* <div className=" footericons px-6 md:px-1 md:w-[200px] w-3/4  flex flex-row p-1 sm:hidden   mt-5 justify-between mx-auto ">
              <a
                href={"#"}
                target={"_blank"}
               
              >
                <FacebookIcon />
              </a>
              <a href={"#"} target={"_blank"}>
                <InstagramIcon /> 
              </a>
              <a href="#" target={"_blank"}>
                <TwitterIcon />
              </a>
              <a href={"#"} target={"_blank"}>
                <LinkedinIcon />
              </a>
            </div> */}

          </div>

        </div>
        <div className="w-11/12  text-center mx-auto py-12 text-[12px] md:text-[14px] lg:text-[16px]  xl:text-[18px] mt-[20px]  h-[20px] border-t-[0.5px]  border-[#C8C8C8]">
          {FooterData?.CopyrightVersionText}
        </div>
      </div>
    </>
  );
}

export default Footer;
