import axios from 'axios';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { env } from '../config';
import * as Actions from "../redux/actions"
import Footer from '../components/Footer';
import LandingPageHeader from '../components/LandingPageHeader';
import LandingPageIntro from '../components/LandingPageIntro';
import LandingPageGetStartedSection from '../components/LandingPageGetStartedSection';
import LandingPageWhyChooseSection from '../components/LandingPageWhyChooseSection';
import LandinPageBubbleSection from '../components/LandinPageBubbleSection';
import LandingPagePricingSection from '../components/LandingPagePricingSection';
import LandingPageKnowMoreCard from '../components/LandingPageKnowMoreCard';
import LandingPageTestimonialSection from '../components/LandingPageTestimonialSection';
import Loader from '../components/Loader';

function LandingPage() {
    const dispatch = useDispatch()


    const getLandingPageData = async () => {
        await axios
            .get(`${env}/api/landing-page-content?populate=deep`)
            .then(async (response: any) => {
                dispatch(Actions.LandingPageAction(response?.data?.data?.attributes));
                dispatch(
                    Actions.FooterAction(response?.data?.data?.attributes?.FooterContent)
                );
                // console.log("HomeDATA", response?.data?.data?.attributes);
            })
            .catch((error: any) => {
                console.log("error", error);
            });
    };


    const LandingData: any = useSelector((state: any) => state?.DataReducer?.LandingPageContent);


    useEffect(() => {

        if (Object.keys(LandingData).length === 0) {

            setTimeout(() => {


                getLandingPageData();
            }, 0);
        }

        window.scrollTo({
            top: 0,
            left: 1,
            behavior: 'smooth'
        });
    }, [])
    var w = window.innerWidth;
    console.log("WWWW", w)

    // console.log("landingData", LandingData?.GetStartedSection)




    return (
        <>
            {

                LandingData?.Intro?.Image?.data?.attributes?.url ?

                    <>
                        <LandingPageHeader ButtonText={LandingData?.Intro?.NavButtonText} background="[#fff7f7]" />
                        <LandingPageIntro Intro={LandingData?.Intro} />
                        <LandingPageGetStartedSection GetStartedSection={LandingData?.GetStartedSection} Intro={LandingData?.Intro} />
                        <LandingPageWhyChooseSection WhyChooseSection={LandingData?.WhyChooseSection} />
                        <LandinPageBubbleSection BubbleSection={LandingData?.BubbleSection} />
                        <LandingPagePricingSection OfferingPagePricingCards={LandingData?.PricingCard} ButtonText={LandingData?.PricingButtonText} Title={LandingData?.PricingTitle} tag={LandingData?.PricingTag} />
                        <LandingPageTestimonialSection TestimonialSection={LandingData?.TestimonialSection} />
                        <LandingPageKnowMoreCard KnowMoreCard={LandingData?.KnowMoreCard} />
                        {/* <Footer wave={true} background={"#FFF7F7"} /> */}
                    </>
                    :
                    <Loader />

            }








        </>
    )
}

export default LandingPage