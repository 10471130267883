import axios from 'axios'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ConnectCard from '../components/ConnectCard'
import FeaturePageFeatureCards from '../components/FeaturePageFeatureCards'
import FeaturesPageTitle from '../components/FeaturesPageTitle'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Loader from '../components/Loader'
import { FeaturesPageTitleVector, SmallLinesinFeatureMainTitle } from '../components/SVGicons'
import { env } from '../config'
import * as Actions from "../redux/actions"

function FeaturesPage() {
  const dispatch = useDispatch();

  const FeatureData: any = useSelector(
    (state: any) => state?.DataReducer?.FeaturesPageContent
  );

  useEffect(()=>{

    setTimeout(() => {
      (async()=>{
        await axios
        .get(`${env}/api/features-page-content?populate=deep`)
        .then(async (response: any) => {
          dispatch(Actions.FeaturesPageAction(response?.data?.data?.attributes));
          // console.log("FeaturesDATA", response?.data?.data?.attributes);
        })
        .catch((error: any) => {
          console.log("error", error);
        });
      })()
    }, 0);


    



  },[dispatch])
 
  // console.log("FEATUREDATA",FeatureData)
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <>
    {
      FeatureData?.FeaturesPageFaceContent?.FeaturePageFaceSVGImage?.data?.attributes?.url?(
        <div className='bg-[#FFF7F7]'>
      <Header background={"#EAEAE0"} activePage={"features"}/>
      <FeaturesPageTitle FeaturesPageFaceContent={FeatureData?.FeaturesPageFaceContent}/>
      <FeaturePageFeatureCards FeaturePageSingleFeatureContent={FeatureData?.FeaturePageSingleFeatureContent}/>
      <ConnectCard background={"#FFF7F7"}/>
      <Footer wave={false} background={"#FFF7F7"}/>
</div>
      ):
      <Loader background={"#EAEAE0"}/>
    }
    </>
  )
}

export default FeaturesPage