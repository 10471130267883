import React from 'react'
import SinglePricingCard from './SinglePricingCard';
import { Link } from 'react-router-dom';

function LandingPagePricingSection(props: any) {
    const { OfferingPagePricingCards, Title, tag, ButtonText } = props;
    return (
        <div className='px-3 lg:px-10  pt-10 my-10  md:py-10 mx-auto  xl:w-5/6'>
            <div className='flex flex-col items-center text-center text-[16px] md:text-[20px]  '>
                <div className='text-[36px] md:text-[48px] mx-auto font-extrabold lg:w-[60%] text-[#1F3934]'>
                    {Title}
                </div>
                <div id="developerConnect" className="px-3 py-1 rounded-md mx-auto w-fit my-5 bg-[#E67B25] " data-aos="fade-up">{tag}</div>
                {/* <div className=" md:my-2 text-center text-[16px] md:text-[20px] xl:w-[60%] lg:mx-auto" data-aos="fade-up">
                   
                </div> */}
                <div className=" md:flex flex-row justify-around my-5 w-full xl:w-[85%] mx-auto  p-2  ">
                    {
                        OfferingPagePricingCards?.map((node: any) => (
                            <SinglePricingCard key={node.PricingType} SinglePricingCardData={node} />
                        ))
                    }
                </div>
                <div className='flex justify-center'>
                    <Link to='https://hrmnest.com/app/' target="_blank">
                        <button className='bg-transparent px-5 py-3 my-8 w-max  border-[#038577] border text-[14px] md:text-[16px] rounded-md hover:text-white hover:bg-[#03776B] active:bg-[#024F46]'>{ButtonText}</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default LandingPagePricingSection