import React from 'react'
import { env } from '../config';
import { WorkFlowManagement } from './SVGicons';
import { Link } from 'react-router-dom';

function LandingPageGetStartedSection(props: any) {
    const { GetStartedSection, Intro } = props;
    return (
        <div className='relative text-[#1F3934] py-16 md:py-20 lg:my-[10%]'>
            <div className='text-[36px] md:text-[48px] font-bold flex flex-col lg:flex-row xl:justify-around xl:w-4/5 mx-5 xl:mx-auto'>
                <div data-aos="fade-up" className='px-3 md:px-0 text-left lg:w-[60%] xl:w-[40%] flex flex-col justify-between'>
                    {GetStartedSection?.Text}
                    <div className=' lg:hidden my-5 flex justify-center'>
                        <img className='z-[7]  relative' src={`${env}${GetStartedSection?.Image?.data?.attributes?.url}`} alt="LoginScreen" />
                    </div>
                    <div className='flex  justify-start my-5'>
                        <Link to='https://hrmnest.com/app/' target="_blank">
                            <button className='hover:bg-[#03776B]  w-max active:bg-[#024F46] bg-[#038577] px-5 py-3 rounded-md text-white mr-4 text-[14px] md:text-[16px]'>{GetStartedSection?.ButtonText}</button>
                        </Link>
                    </div>

                </div>
                <div className='hidden lg:flex items-center '>
                    <img data-aos="fade-left" className='z-[7]  relative' src={`${env}${GetStartedSection?.Image?.data?.attributes?.url}`} alt="LoginScreen" />
                </div>
            </div>
            <svg data-aos="fade-left" className='absolute hidden lg:block right-0 bottom-[10%] scale-125 ' xmlns="http://www.w3.org/2000/svg" width="348" height="410" fill="none"><path fill="#D1E9E6" d="M0 190.5C0 85.29 85.29 0 190.5 0H348v410H0V190.5Z" /></svg>


        </div>
    )
}

export default LandingPageGetStartedSection

