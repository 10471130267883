import React from 'react'
import { DownWave } from './SVGicons';
import { env } from '../config';
import { Link } from 'react-router-dom';

function LandingPageIntro(props: any) {
    const { Intro } = props;

    return (
        <div data-aos="fade-down" className='relative '>
            <div className='bg-[#fff7f7]  text-[#1F3934] '>
                <div className='lg:px-[8%] pb-10 pt-20 lg:pt-40 lg:pb-20 '>
                    <div className='text-[36px] md:text-[48px] mx-auto font-extrabold lg:w-[70%] xl:w-[50%] text-center'>
                        {Intro?.Title}
                    </div>
                    <div className='py-6 flex flex-col items-center justify-center text-[16px] md:text-[20px] px-3 '>
                        <div className='text-center'>{Intro?.Description1}</div>
                        <div className='text-center xl:w-2/3'>{Intro?.Description2}</div>
                    </div>
                    <div className='w-max mx-auto flex justify-between py-6 '>
                        <Link to='https://hrmnest.com/app/' target="_blank">

                            <button className='hover:bg-[#03776B] active:bg-[#024F46] bg-[#038577] px-5 py-3 rounded-md text-white mr-4 text-[14px] md:text-[16px]'>{Intro?.PrimaryButtonText}</button>
                            <button className='bg-transparent px-5 py-3 border-[#038577] border text-[14px] md:text-[16px] rounded-md hover:text-white hover:bg-[#03776B] active:bg-[#024F46]'>{Intro?.SecondaryButtonText}</button>
                        </Link>
                    </div>
                </div>
            </div>
            <DownWave background="#fff7f7" />
            < img data-aos="zoom-in" className=" mx-auto mb-10 mt-[-10%] z-[7] relative px-3" src={`${env}${Intro?.Image?.data?.attributes?.url}`} alt="HRMnest Landing Hero" />
        </div>

    )
}

export default LandingPageIntro


