import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ConnectCard from "../components/ConnectCard";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HomeFeaturesOverview from "../components/HomeFeaturesOverview";
import HomePageIntro from "../components/HomePageIntro";
import HomePageModulesOverview from "../components/HomePageModulesOverview";
import HomePageTitle from "../components/HomePageTitle";
import Loader from "../components/Loader";


function Home() {

  const HomeData: any = useSelector(
    (state: any) => state?.DataReducer?.HomePageContent
  );
  // console.log("HOMEPAGE",HomeData)




  useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 1,
        behavior: 'smooth'
    });
}, [])
  



  return (
    <>
    {
      HomeData?.HomePageFaceContent?.HomeFaceSVGImage?.data?.attributes?.url?
      <>
    <Header background={"#FAE7D7"} activePage={"home"}/>
    <HomePageTitle HomePageFaceContent={HomeData?.HomePageFaceContent} />
    <HomePageIntro HomePageIntroContent={HomeData?.HomePageIntroContent}/>
    <HomeFeaturesOverview HomePageFeatureOverviewContent={HomeData?.HomePageFeatureOverviewContent}/>
    <HomePageModulesOverview HomePageModuleOverviewContent={HomeData?.HomePageModuleOverviewContent}/>
    <ConnectCard background={"white"}/>
    <Footer wave={true} background={"#FDF4ED"} />
      </>:
    <Loader background="#FAE7D7"/>
    }
    </>
  );
}

export default Home;
